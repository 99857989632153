import {
    VStack,
    Text,
    Grid,
    Link,
    Box,
} from '@chakra-ui/react';
import ChatAI from '../components/ChatAI';

const Assistant = () => {  
    
    return (
        <Box textAlign="center" fontSize="xl" bg="#eee">
        <Grid p={3} >

            <Box >
            <ChatAI/>
            </Box>
          
        </Grid>
      </Box>
    )
    
}


export default Assistant