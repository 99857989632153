import React from 'react';
import { BrowserRouter, Route, Routes } from "react-router-dom";
import {
  ChakraProvider,
  theme,
} from '@chakra-ui/react';
import { ColorModeSwitcher } from './ColorModeSwitcher';
import Assistant from './views/Assistant';
import Docus from './views/Docus';
import "./assets/css/styles.css"

// import { Logo } from './Logo';

function App() {
  return (
    <ChakraProvider theme={theme}>
      <BrowserRouter>
        <Routes>
        <Route name='home' path="/"  element={<Assistant/>} />
        <Route name='docus' path="/docus"  element={<Docus/>} />
        </Routes>
      </BrowserRouter>

    </ChakraProvider>
  );
}

export default App;
