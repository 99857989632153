import React, { useEffect, useState, useRef } from 'react';
import {
    Box,
    Text,
    Link,
    VStack,
    Stack,
    Image,
    Input,
    Grid,
    Circle,
    Button,
    Flex,
    HStack,
    Progress,
    Textarea,
    Divider,
    useDisclosure,
    Drawer,
    DrawerOverlay,
    DrawerCloseButton,
    DrawerHeader,
    DrawerBody,
    DrawerContent,
    DrawerFooter,
    FormLabel,
    InputGroup,
    InputLeftAddon,
    InputRightAddon,
    Select,
    NumberInput,
    NumberInputField,
    Heading
  } from '@chakra-ui/react';
import Chat, { Bubble, useMessages, ScrollView, RichText } from '@chatui/core';
import { AiFillSound, AiOutlineSound } from "react-icons/ai";
import '@chatui/core/dist/index.css';
import axios from "axios";
import { MdSend } from "react-icons/md";
import { BsMoonStarsFill, BsSun, BsUpload, BsFile } from "react-icons/bs";
import {GiBroom} from "react-icons/gi";
import { AudioRecorder, useAudioRecorder } from 'react-audio-voice-recorder';
import { fetchEventSource } from '@microsoft/fetch-event-source';
import { ColorModeSwitcher } from '../ColorModeSwitcher';
import { useSearchParams } from "react-router-dom";
import { FaCamera } from "react-icons/fa";
import { motion, useAnimation } from "framer-motion";

// import { Client } from '@anthropic-ai/sdk';
// import hljs from 'highlight.js';
// import Highlight from "react-highlight";
// import "highlight.js/styles/github.css";

const ChatAI = ({currentUser}) => {
  
    const [idModel, setIdModel] = useState('gpt-4o-mini');
    const [modelVoice, setIdModelVoice] = useState('es-US-Studio-B');
    const [idModelMaxToken, setIdModelMaxToken] = useState(690);
    const { messages, appendMsg, setTyping, updateMsg, resetList } = useMessages([]);
    const [ListAudioResponse, setListAudioResponse] = useState([]);
    const countPlay = useRef(0);
    const BoxTextInput = useRef(null);

    const [playing, setPlaying] = useState(false);
    const [isTranscrip, setisTranscrip] = useState(false);
    const [silence, setsilence] = useState(false);
    const [textInput, setTextInput] = useState("");
    const [TypeInput, setTypeInput] = useState(true);
    const recorderControls = useAudioRecorder();
    const [searchParams] = useSearchParams();
    const [modoDark, setModoDark] = useState(true);

    const corpus_id = searchParams.get("corpus_id");
    const init_resume = searchParams.get("init_resume");
    const voice = searchParams.get("voice");
    const model = searchParams.get("model");

    const [APIConfig, setAPIConfig] = useState();
    const KeyAI = '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';
    const ctrl = new AbortController();

    const { isOpen, onOpen, onClose } = useDisclosure();
    const { isOpen: isOpenFile, onOpen: onOpenFile, onClose: onCloseFile } = useDisclosure();
    // const { isOpenFile, onOpenFile, onCloseFile } = useDisclosure();

    const firstField = React.useRef()
    const firstFieldFiles = React.useRef()
    
    const [countFaill, setcountFaill] = useState(0);
    const [imageData, setImageData] = useState("");
    const inputRefImg = useRef();

    const FileDoc = useRef();
    const controls = useAnimation();
    const startAnimation = () => controls.start("hover");
    const stopAnimation = () => controls.stop();

    const [files, setFiles] = useState([]);
    const [filesDataTextPlain, setFilesDataTextPlain] = useState("");

    // console.log(filesDataTextPlain);
    

    const uploadFile = () => {
      const fileType = FileDoc.current.files[0].type;
      if (fileType.includes("text")
        || fileType.includes("json")
        || fileType.includes("shellscript")
        || fileType.includes("csv")
    ){
        setFiles((files) => [...files, FileDoc.current.files[0] ]);
      } else {
        alert("Error: Solo se permiten archivos de código en texto plano. Por favor, intenta nuevamente.");
      }
    }



    const adjuntarFicheros = () => {
      let dataPlaintex = []; // Inicializa un array para almacenar el contenido de los archivos
      
      console.log(files);
      
      // Promesas para leer el contenido de los archivos
      const readFiles = files.map(file => {
        return new Promise((resolve, reject) => {
          const reader = new FileReader();
          
          reader.onload = (event) => {
            // Almacena el contenido de cada archivo en el array
            dataPlaintex.push(event.target.result);
            resolve(); // Resolvemos la promesa cuando se carga el archivo
          };
    
          reader.onerror = (error) => {
            reject(error); // Rechazamos la promesa en caso de error
          };
    
          // Leer el archivo como texto
          reader.readAsText(file);
        });
      });
    
      // Esperamos que todas las promesas se resuelvan
      Promise.all(readFiles)
        .then(() => {
          // Aquí puedes hacer algo con dataPlaintex
          // console.log(dataPlaintex); // Muestra el contenido de los archivos
          const combinedData = dataPlaintex.join("\n"); // Usa '\n' para separar los archivos por líneas
          // console.log(combinedData);
          setFilesDataTextPlain(combinedData);
        })
        .catch((error) => {
          console.error("Error al leer los archivos:", error);
        });
    };




    function mostrarImagen() {
      setIdModel("gpt-4o-mini");
      if (inputRefImg.current.files && inputRefImg.current.files[0]) {
          const reader = new FileReader();
          reader.onload = function(e) {
            setImageData(e.target.result);
          };
          
          reader.readAsDataURL(inputRefImg.current.files[0]);
      }
  }


  const ModalFiles = () => (
    <Drawer
    zIndex={9999999999}
    isOpen={isOpenFile}
    placement='left'
    initialFocusRef={firstFieldFiles}
    onClose={onCloseFile}
  >
    <DrawerOverlay />
    <DrawerContent >
      <DrawerCloseButton />
      <DrawerHeader borderBottomWidth='1px'>
        Cargar Archivos
      </DrawerHeader>

      <DrawerBody p={1}>
        <Stack >

        <Box bg="rgb(0, 21, 41)">
            <Box
            cursor={"pointer"}
                m="3"
                height={"130px"}
              borderColor="gray.300"
              borderStyle="dashed"
              borderWidth="2px"
              rounded="md"
              shadow="sm"loa
              role="group"
              transition="all 150ms ease-in-out"
              _hover={{
                shadow: "md"
              }}
              initial="rest"
              animate="rest"
              whileHover="hover"
              alignContent={"center"}
              onClick={() => FileDoc.current.click()}
            >
            
                  <Stack
                    display="flex"
                    alignItems="center"
                    justify="center"
                    spacing="2"
                  >
                    
                    <Stack p="5" textAlign="center" spacing="1" placeItems={"center"}>
                      <>
                        <Heading fontSize="lg" color="white" fontWeight="bold">
                         Cargar Código Aquí
                        </Heading>
                        <Text color="white"  fontWeight="light">
                          Tamaño máximo permitido: 1 MB.
                        </Text>
                      </>
                      
                    </Stack>
                    
                  </Stack>
                <Input
                    ref={FileDoc}
                  type="file"
                  height="130px"
                  width="20%"
                  position="absolute"
                  top="0"
                  left="0"
                  opacity="0"
                  aria-hidden="true"
                  accept="*"
                  onDragEnter={startAnimation}
                  onDragLeave={stopAnimation}
                  onChange={uploadFile}
                />
            </Box>



            <Box m="2" textAlign={"center"}>
                  <Heading pt="1" m="1" fontSize="md" color="white" textAlign={"center"}>
                      Ficheros:
                    </Heading>
                {files?.map((item, key) => (
                <Button
                my="1"
                colorScheme='cyan'
                variant='outline'
                  key={key}
                  size='md'
                  width='99%'
                  border='2px'
                >
                  {item.name}
                </Button>
                ))}
            </Box>

       </Box>

      <DrawerFooter alignSelf={"center"}  flexDirection={"column"}>

        <Box>
        <Button variant='solid' bg={"mediumspringgreen"} fontSize={"18px"} onClick={adjuntarFicheros}>
          Adjuntar Ficheros al Chat
        </Button>
        </Box>

        <Box width={"100%"}>
        <Button mt={3} variant='outline' onClick={onCloseFile} width={"100%"}>
              Cerrar
        </Button>
        </Box>
    

      </DrawerFooter>

        </Stack>
      </DrawerBody>

    </DrawerContent>
  </Drawer>
  )




    const ModalConfig = () => (

      <Drawer
        zIndex={9999999999}
        isOpen={isOpen}
        placement='right'
        initialFocusRef={firstField}
        onClose={onClose}
      >
        <DrawerOverlay />
        <DrawerContent >
          <DrawerCloseButton />
          <DrawerHeader borderBottomWidth='1px'>
            Personalizar Chat
          </DrawerHeader>

          <DrawerBody>
            <Stack spacing='24px'>

              <Box>
                <FormLabel htmlFor='model'>Seleccionar modelo</FormLabel>
                <Select id='model' defaultValue={idModel} onChange={(e) => setIdModel(e.target.value)} >
                  <option value='gpt-4o-mini'>gpt-4o-mini</option>
                  <option value='chatgpt-4o-latest'>GPT-4o-latest</option>
                  <option value='o1-mini'>o1-preview</option>
                </Select>
              </Box>

              <Box>
                <FormLabel htmlFor='idModelMaxToken'>MaxToken</FormLabel>
                <NumberInput
                    defaultValue={idModelMaxToken}
                    onChange={(e) => setIdModelMaxToken(Number(e))}
                    id='idModelMaxToken'                
                >
                  <NumberInputField/>
                </NumberInput>
              </Box>

          <DrawerFooter alignSelf={"center"}>
            <Button variant='outline' onClick={onClose}>
              Cerrar
            </Button>
          </DrawerFooter>

            </Stack>
          </DrawerBody>

        </DrawerContent>
      </Drawer>
    )


    const ClearChat = () => {
      ctrl.abort();
      console.log('clear chat');
      resetList();
      setPlaying(false);
      setListAudioResponse([]);
      countPlay.current = 0;
      ListAudioResponse.forEach((item) => {
        item.audio.pause(); 
      })
    }

    const changeDark = () => {
      const elements = document.getElementsByClassName("ChatApp");
      const elementsRichText = document.getElementsByClassName("RichText");

      
      if (modoDark){
        for (var i = 0; i < elements.length; i++) {
          elements[i].style.backgroundColor="#eee";
        }
        for (var i = 0; i < elementsRichText.length; i++) {
          elementsRichText[i].style.color="black";
        }
      } else {
        for (var i = 0; i < elements.length; i++) {
          elements[i].style.backgroundColor="#00080E";
        }
        for (var i = 0; i < elementsRichText.length; i++) {
          elementsRichText[i].style.color="white";
        }
      }

      setModoDark((modoDark) => !modoDark);

    }

    const OnSound = (state) => {
        if (state){
          setsilence(true);
          ListAudioResponse.forEach((item) => {
              item.audio.pause();  
          })
        } else {
          setsilence(false);
          countPlay.current = 1;
          setPlaying(false);

        }
      }

      
    const InitChatAI = (data) => {
        console.log('init chat');
    
        if (messages.length == 0){
            BoxTextInput.current.focus();
            setTyping(true);
            appendMsg(
              {
                type: 'text',
                content: { text: data.start},
                user: { avatar: 'assets/img/eva.png' },
              }
            );
            setTyping(false);

        }
      }


    useEffect( () => {

      if (ListAudioResponse){
          ListAudioResponse.forEach((item) => {
          // console.log('debugg',countPlay.current, !playing);
          if (item.index == countPlay.current && !playing){
              setPlaying(true);
              item.audio.play();
          }

        })

      }
    }, [ListAudioResponse, playing, silence])
    


    useEffect( () => {

        if (recorderControls.isRecording){
          ctrl.abort();

          setTypeInput(false);
          setListAudioResponse([]);

          try {
            const micIcon = document.querySelector('.audio-recorder-mic[data-testid="ar_mic"]');
            const micIconPause = document.querySelector('.audio-recorder-options[data-testid="ar_pause"]');
            micIcon.style.display = "none";
            micIconPause.style.display = "none";
          } catch (error) {
            console.log(error);
          }
            console.log('isRecording', recorderControls.isRecording);

        } else {
          try {
            const micIcon = document.querySelector('.audio-recorder-mic[data-testid="ar_mic"]');
            micIcon.style.display = "block";              
          } catch (error) {
            console.log(error);
          }
        }
    }, [recorderControls.isRecording])


    useEffect( () => {

      if (voice === "0"){
        setsilence(true);
      }

      if (model === "3"){
        setIdModel("gpt-4o-mini");
      }

      // changeDark();
      
      if (!APIConfig){
        // hljs.highlightAll();
        axios.get('https://ja19hqi7hg.execute-api.us-east-1.amazonaws.com/dev').then(
          (response) => {
            setAPIConfig(response.data.body.data[searchParams.get("config") || "default-dev" ]);
            InitChatAI(response.data.body.data[searchParams.get("config") || "default-dev" ]);
          }
        ).catch((e) => {
            console.log(e)
        })  
      } else {

        if (init_resume === '1'){
          handleSend(`Resuma el documento en 3 oraciones y generar 2 preguntas de prueba pero no proporcione respuestas. Agregue "Hola!, ha subido ${corpus_id}" en la primera linea.`, false);
        }
      }
    }, [])



    const limpiarTexto = (text) => {
      return text
      .replace("###", '') // 
      .replace("####", '') // 
      .replace("#####", '') // 
      .replace(/</g, '') // Remover etiquetas HTML de apertura
          .replace(/>/g, '') // Remover etiquetas HTML de cierre
          .replace(/\*/g, '') // Remover asteriscos
          .replace(/_/g, '') // Remover guiones bajos
          .replace(/~/g, '') // Remover virgulillas
          .replace(/&/g, 'y') // Reemplazar ampersand por 'y'
          .replace(/%/g, 'por ciento') // Reemplazar porcentaje
          .replace(/\$/g, 'dólares') // Reemplazar dólar
          .replace(/#/g, 'número') // Reemplazar numeral
          .replace(/@/g, 'arroba') // Reemplazar arroba
          .replace(/(\d+)/g, (match) => match.replace(/\B(?=(\d{3})+(?!\d))/g, ',')); // Formatear números
  }

    const TextToSpeech = async (item) => {

        if (silence){
          return
        }

        let textClear = item.text.replace(/```[^```]*```/g, '').replace(/`/g, '');
        textClear = textClear.replace(/<[^>]*>?/gm, '');

        textClear = limpiarTexto(textClear);
        textClear = textClear.trim();

        if (!textClear){
          return
        }

        await axios.post('https://xcfnpymo45.execute-api.us-east-1.amazonaws.com/dev/', {
          text: textClear,
          voice: modelVoice
        }, {
          withCredentials: false,
          headers: {
            Authorization:"",
            'Content-Type': 'application/json'
          },
        })
        .then(res => {
            const audioResponse = res.data.body.audio;

            const myaudio = new Audio(`data:audio/mp3;base64,${audioResponse}`);
            myaudio.preload = 'auto';
            myaudio.addEventListener('ended', (e) => {
              // console.log('termino audio');
              setPlaying(false);
              countPlay.current = countPlay.current + 1;
            });


          setListAudioResponse(ListAudioResponse => [...ListAudioResponse, {audio: myaudio, index: item.index}]);

        }).catch(error => {
          console.log(error);
        })
      }

  
  
  const GenerateContext = async (text) => {
    setTyping(true);
    const data = {
      query: text,
      "corpus_id": corpus_id
    }

    try {
      // const API = 'http://localhost:8000/query';
      const API = 'https://api-docus.dojopy.com/query';
      const response = await axios.post(API, data , {
        timeout: 20000,
        headers: {
          'Content-Type': 'application/json'
        },
      })
      return response.data.response;
    } catch (error) {
      setTyping(false);
      console.log(error);
    }

  }


  const agregarEtiquetaPre = (texto) => {
    const tripleComilla = '```';
    const indice = texto.indexOf(tripleComilla);
      // si hay codigo detectar y agregar prefijo pre.
    if (indice !== -1) {
      const antes = texto.slice(0, indice);
      const despues = texto.slice(indice);
      const parseres = antes + '<pre class="language-html" style="color:red">' + despues;
      return parseres.replace(/```(.*?)```/gs, '<pre><code>$1</code></pre>');
    }
    return texto;

  }

  function convertirBold(text) {
    return text.replace(/\*\*(.*?)\*\*/g, '<b>$1</b>');
}

  const convertTripleQuotes = (text) => {
    let data;
    data = text.replace(/</g, '&lt;').replace(/>/g, '&gt;');
    data = agregarEtiquetaPre(data);

    data = convertirBold(data);

    return data;
  }


  const handleSend = async (voiceText, addMsg=true) => {

    console.log("filesDataTextPlain", filesDataTextPlain);
    
    try {
      if (BoxTextInput){
        BoxTextInput.current.value = "";
        BoxTextInput.current.style.height = "58px";
        setTextInput("");
      }
    } catch (error) {
        console.log(error);
    }

      let dataContext = '';
      let messagesGPT = [];

      if(APIConfig?.agente){
        dataContext = await GenerateContext(voiceText || textInput.trim());
        if (!dataContext){
          setTyping(false);
          return;
        }
      }


      if (!voiceText && !textInput.trim()){
        return;
      }

      // especial comando
      const msgRead = voiceText || textInput.trim();
      if (msgRead === '/config'){
        onOpen();
        return;
      } 

        countPlay.current = 1;

        ListAudioResponse.forEach((item) => {
          item.audio.pause(); 
        })
        setListAudioResponse([]);
        setPlaying(false);

        if (voiceText || textInput.trim()) {
          
          if (addMsg){
            appendMsg({
              type: 'text',
              content: { text: `${voiceText || textInput.trim()}`, image_url: imageData },
              position: 'right',
              user: { avatar:  "assets/img/user.png"},
            });
          }
    
    
          // respuesta de API CHATGPT
          setTyping(true);
          let msgs = [];
    
          if (!APIConfig?.agente){
            console.log("no hay agente");
            
            messagesGPT = messages?.map((item, index) => {
        
              if (item.position === 'left'){
                if (!msgs.includes(item.content.text)){
                  msgs.push(item.content.text);
                  return  {
                    "role": "assistant",
                    "content": item.content.text
                  }                    
                } else {
                  return false
                }
              }

              if (item.content.image_url){
                return  {
                  "role": "user",
                  "content": [
                    {"type": "text", "text": item.content.text},
                    {"type": "image_url",  "image_url": {"url": item.content.image_url} }
                  ],
                } 
              } else if (filesDataTextPlain){
                console.log("oaksoakos");
                return  {
                  "role": "user",
                  "content": [{"type": "text", "text": item.content.text + "\n\n CODIGO ADJUNTO:\n" + filesDataTextPlain }],
                } 
              } else {
                return  {
                  "role": "user",
                  "content": [{"type": "text", "text": item.content.text}],
                } 
              }
              
            })
          }
     

          if (!APIConfig?.agente){
            // si hay intrucciones cargar data
            messagesGPT.unshift({
              "role": "user",
              "content": APIConfig?.instruccion_config
            });

            messagesGPT.unshift({
              "role": "system",
              "content": APIConfig?.identidad_config
            });
            
            if (imageData){
              messagesGPT.push({
                "role": "user",
                "content": [
                    {"type": "text", "text": `${ voiceText || textInput }`},
                    {"type": "image_url", "image_url": { "url": imageData} },
                ]
              });
            } else if (filesDataTextPlain){
              messagesGPT.push({
                "role": "user",
                "content": [
                    {"type": "text", "text": `${ voiceText || textInput + "\n\n CODIGO ADJUNTO:\n" + filesDataTextPlain }`},
                ]
              });
            } else {
              messagesGPT.push({
                "role": "user",
                "content": [
                    {"type": "text", "text": `${ voiceText || textInput }`},
                ]
              });
            }

          } else {
                // si es Agente Personalizado
                // si hay intrucciones cargar data
                messagesGPT.unshift({
                  "role": "user",
                  "content": [
                    {"type": "text", "text": `${APIConfig?.instruccion_config} ${dataContext}`},
                  ]
                });

                messagesGPT.unshift({
                  "role": "system",
                  "content": APIConfig?.identidad_config
                });

          }

          messagesGPT = messagesGPT.filter(ele => ele !== false);

          const data = JSON.stringify({
            "messages": messagesGPT,
            "temperature": APIConfig?.agente ? 0.6 : 0.85,
            "max_tokens": APIConfig?.agente ? 495 : idModelMaxToken,
            "top_p": 1,
            "frequency_penalty": 0,
            "presence_penalty": 0,
            "model": APIConfig?.agente ? 'gpt-4o-mini' : idModel,
            "stream": true
          });

      
        let wordsComplete = "";
        let wordsCompleteFrase = "";
        let timeUnique = false;
        let contadorFrases = 0;
        // let countFaill = 0;

        setImageData("");
        setFilesDataTextPlain("");
        setFiles([]);

        // const ctrl = new AbortController();
        await fetchEventSource('https://api.openai.com/v1/chat/completions', {
          method: 'POST',
          headers: {  
              'Content-Type': 'application/json',
              "Authorization": `Bearer ${KeyAI}`
          },
          body: data,
          signal: ctrl.signal,
          
          onmessage(ev) {
            setTyping(false);
            const msg = JSON.parse(ev.data)['choices'][0]['delta']['content'];
            const estado = JSON.parse(ev.data)['choices'][0]['finish_reason'];

            if (msg){
              // console.log(msg);
              wordsComplete += msg;
              wordsCompleteFrase += msg;

              // const wordsCompleteFraseSplit = wordsCompleteFrase.split(/[.]+\s|\?|\!|\n/);
              const wordsCompleteFraseSplit = wordsCompleteFrase.split(/[.]+\s|\n\n/);

              

              // console.log(wordsCompleteFrase.length);

              if (wordsCompleteFraseSplit.length >= 1){

                // console.log(wordsCompleteFraseSplit);
                const fraseArray = wordsCompleteFraseSplit.slice(0, wordsCompleteFraseSplit.length - 1);
                const lastChunk = wordsCompleteFraseSplit[wordsCompleteFraseSplit.length - 1];
                const textChunk = fraseArray.join(' ');
                
                // console.log('fraseArray', fraseArray);
                if (textChunk.length > 5){
                  // console.log(textChunk);
                  contadorFrases++;
                  TextToSpeech({'text': textChunk, 'index': contadorFrases});
                  wordsCompleteFrase = ` ${lastChunk}`;
                }
              }

              if (!timeUnique){
                timeUnique = Date.now();
                appendMsg({
                  _id: timeUnique,
                  type: 'text',
                  content: { text: msg},
                  user: { avatar: 'assets/img/eva.png' },
                });

                try {
                  if (BoxTextInput){
                    setTextInput("");
                    BoxTextInput.current.value = "";
                    BoxTextInput.current.style.height = "58px";
                  }
                } catch (error) {
                    console.log('');
                }
                
              } else {
                
                // console.log(wordsComplete);
                updateMsg(timeUnique, {
                  type: 'text',
                  content: { text: wordsComplete},
                  user: { avatar: 'assets/img/eva.png' },
                });
              }
            }


            if (estado === 'stop' || estado === 'length'){
              if (wordsCompleteFrase){
                if (wordsCompleteFrase.includes("¿") && !wordsCompleteFrase.includes("?")){
                    wordsCompleteFrase += "?";
                }
                if (wordsCompleteFrase.includes("¡") && !wordsCompleteFrase.includes("!")){
                  wordsCompleteFrase += "!";
                }
                TextToSpeech({'text': wordsCompleteFrase, 'index': contadorFrases+1});
              }
              ctrl.abort();
              setTyping(false);

            }

        },
      async onopen(response) {
        if (response.ok) {
            return; // everything's good
        } else if (response.status >= 400 && response.status < 500 && response.status !== 429) {
            // client-side errors are usually non-retriable:
            setTyping(false);
            ctrl.abort();
            throw false;
        } else {
          setTyping(false);
          ctrl.abort();
          throw false;
        }
    },
      });


        }
  }
    


      const SpeechToText = (blob) => {
        if (recorderControls.isRecording || TypeInput){
            return;
        }
        setisTranscrip(true);
        const formData = new FormData();

        const file = new File([blob], 'sample.webm');

        formData.append('file',file);
        formData.append('model',"whisper-1");
        formData.append('language',"es");
        
        axios.post('https://api.openai.com/v1/audio/transcriptions',formData, {
          timeout: 25000,
          headers: {
            Authorization:`Bearer ${KeyAI}`,
            'Content-Type': 'multipart/form-data'
          },
        })
        .then(res => {
            setisTranscrip(false);
            const responseApi = res.data.text;
            handleSend(responseApi);
    
        }).catch(error => {
          setisTranscrip(false);
          console.log(error);
        })
      }


      const renderMessageContent = (msg) => {
        const links = [];
        const regex = /(https?:\/\/[^\s]+)/g;
        const { content } = msg;
        const text = content.text;

        // Buscar enlaces
        // if (text.includes('http')){
        //   let match;
        //   while ((match = regex.exec(text)) !== null) {
        //     let enlace = match[0].replace('"', '').replace('`', ''). replace("'", ''). replace('.pdf.', '.pdf');
            
        //     if(enlace.endsWith('.')){
        //       enlace = enlace.slice(0, -1);
        //     }
        //     links.push(enlace);
        //   }
        // }

        // Buscar numeros
        // const numbers = text.match(/\+\d{5,15}/g);
        // numbers?.map((number) => {
        //   links.push(`https://wa.me/${number}?text=Hola%20Dojopy%20Full%20Stack`)
        // })
   
    const imageMessage = content?.image_url;

    return (
      <Box
        fontSize={"18px"}
        textAlign={"left"}
        marginX={"5px"}
        paddingX={"5px"}
        paddingY={"5px"}
        borderRadius={"15px"}
        whiteSpace={"pre-line"}
        color={"white"}
        >
          <RichText content={convertTripleQuotes(content.text)}  />

    { imageMessage &&
      <Stack direction='row' my="1">
      <Image
        style={{
          border: "2px solid white",
          borderRadius: "20px"
        }}
        boxSize='250px'
        objectFit='cover'
        src={imageMessage}
        alt=''
      />
    </Stack>
          }

          {links.length > 0 &&
          <>
          <Divider />
          <Text mt="2" mb="1" textAlign={"left"} fontWeight={"bold"} fontSize={"16px"}>
            Enlaces:
          </Text>
          <Box alignContent={"flex-start"} textAlign="left" width={"100%"}>
            {links.map((url, key) => (
              <Button key={key} fontSize={"16px"} margin={"1"} size='md' colorScheme={"whatsapp"} onClick={() => {window.open(url, '_blank')}} >{url.slice(0,30)}</Button>
            ))}
          </Box>
  
          </>
          }
          
      </Box>
        
      
          )
      }



      return  (
        <>

        <ModalFiles/>
          {ModalConfig()}

      <VStack>

        <HStack
        width={"100%"}
        background={"#00080E"}
          color={"white"}
          position="fixed"
          top={0}
          left={0}
          padding="5px"
          zIndex={999}>

        {!silence ?
          <Button  onClick={() => OnSound(true)} backgroundColor={"transparent"} borderRadius="40%" isActive={!silence} >
          <AiFillSound style={{fontSize:"19px", color: "purple"}} />
        </Button> :
        <Button onClick={() => OnSound(false)} backgroundColor={"transparent"} borderRadius="40%" >
          <AiOutlineSound style={{fontSize:"19px", color: "purple"}} />
        </Button>
        }

        <Button onClick={() => ClearChat()} backgroundColor={"transparent"} borderRadius="40%" >
          <GiBroom style={{fontSize:"19px", color: "white"}} />
        </Button>


        <Button onClick={() => changeDark()} backgroundColor={"transparent"} borderRadius="40%" >
          {!modoDark? <BsMoonStarsFill style={{fontSize:"19px", color: "white"}} />: <BsSun style={{fontSize:"19px", color: "white"}} />}
        </Button>


        <Box style={{display: "flex", alignItems: "center", marginLeft: "20px"}} onClick={onOpenFile}>
           <BsUpload style={{fontSize:"23px", color: "mediumspringgreen"}} />
          </Box>


        <Box style={{display: "flex", alignItems: "center", marginLeft: "20px"}}>
                <Select id='modelVoice' defaultValue={modelVoice} onChange={(e) => setIdModelVoice(e.target.value)} >
                  <option value='es-US-Studio-B'>Voz Elon Musk</option>
                  <option value='es-US-Neural2-A'>Voz Claudia</option>
                  <option value='es-US-Neural2-C'>Voz Marco</option>
                </Select>
          </Box>


        {/* <Button colorScheme='teal' onClick={onOpen}>
        Create user
      </Button> */}


{/* <ColorModeSwitcher /> */}

        </HStack>

        <Box position={"absolute"} top="45px" left={"0"} width={"100%"} height="90vh">
        <Chat
        placeholder='Hola! tienes alguna duda?'
        locale="es-US"
        messages={messages}
        renderMessageContent={renderMessageContent}
        onSend={handleSend}
        />

        </Box>

            <Box style={{backgroundColor: modoDark ? "#00080E": "#eee" , marginLeft: "0", position: "fixed", bottom: "0", left: "0", zIndex: "9999", width: "100%" }}>

{imageData && 
<Stack direction='row' mx="3" my="1">
  <Image
    style={{
      border: "2px solid white",
      borderRadius: "20px"
    }}
    boxSize='99px'
    objectFit='cover'
    src={imageData}
    alt=''
  />
</Stack>
 }

<Flex m="3"  direction={"column"} alignItems={"flex-start"} gap={"5px"}>
{files?.map((item, key) => (
  <>
    <p style={{fontSize: "17px", color: "white"}}>
    <BsFile fontSize={"25px"} color='white' style={{display: "inline", marginRight: "5px"}}/>
      {item.name}
    </p>
  </>
))}
</Flex>

            <Flex>
                {!recorderControls.isRecording && !isTranscrip &&
                  <Textarea
                  ref={BoxTextInput}
                  minHeight={"58px"}
                  paddingTop={"15px"}
                  variant="outline"
                  maxHeight={"350px"}
                  resize="none"
                        size='lg'
                      value={textInput}
                      placeholder="Realiza una pregunta"
                      style={{borderRadius: "18px", border: "none", color: modoDark ? "#eee": "#00080E", backgroundColor: modoDark ? "#00080E": "white" , boxShadow: "#239aff 1px 1px 10px 0px", fontSize: "19px", marginRight: "5px", margin: "3px"}}
                      onChange={(e) => {
                          setTextInput(e.target.value);
                          setTypeInput(true);
                      }}
                      onInput={(element) => {
                        element.target.style.height = "5px";
                        element.target.style.height = (element.target.scrollHeight)+"px";
                      }}
                      onKeyPress={event => {
                          if (event.key === "Enter") {
                            handleSend();
                          }
                        }}
                  />}

                  
                  {isTranscrip &&
                  <Box position={"absolute"} width="100%" bottom={"10px"} backgroundColor={modoDark ? "#00080E": "#eee"} >
                    <Progress colorScheme="purple" height="18px"  isIndeterminate backgroundColor={modoDark ? "#00080E": "#eee"} />
                  </Box>
                  }
                  

                {!textInput &&
                <span onClick={() => {
                  ListAudioResponse.forEach((item) => {
                    item.audio.pause(); 
                  })
                  setListAudioResponse([]);
                  recorderControls.startRecording();
                } }
                style={{display: isTranscrip ? "none": "block",  marginLeft: "5px", marginRight: "9px", padding: "5px", width: recorderControls.isRecording ? "285px": "50px", cursor: "pointer" }}>
                  <AudioRecorder
                    onRecordingComplete={(blob) => SpeechToText(blob)}
                    recorderControls={recorderControls}
                    />
                </span>
               }


                  {textInput &&

                <Circle
                    cursor={"pointer"}
                    style={{maxWidth: "65px", marginLeft: "5px", marginRight: "9px"}}
                    onClick={() => handleSend()}
                    transition={"transform .3s"}
                    _hover={{
                        transform: "scale(1.15)"
                    }}
                        size="50px"
                        bg="whitesmoke">
                    <MdSend style={{color: "#2ECC71", fontSize: "33px"}} />
                </Circle>

                    }

            {model !== "3" && <Circle
                    cursor={"pointer"}
                    style={{maxWidth: "65px", marginLeft: "5px", marginRight: "9px"}}
                    onClick={() => inputRefImg.current.click() }
                    transition={"transform .3s"}
                        size="50px"
                        bg="whitesmoke">
                <input onChange={() => mostrarImagen() } ref={inputRefImg} type="file" accept="image/*" capture="camera" style={{display: "none"}}/>
                    <FaCamera style={{color: "black", fontSize: "29px"}} />
                </Circle>
            }


              {recorderControls.isRecording &&
                    <Circle
                    cursor={"pointer"}
                    style={{maxWidth: "65px", marginLeft: "5px", marginRight: "5px"}}
                    onClick={() => recorderControls.stopRecording()}
                    transition={"transform .3s"}
                    _hover={{
                        transform: "scale(1.15)"
                    }}
                        size="50px"
                        bg="whitesmoke">
                    <MdSend style={{color: "#2ECC71", fontSize: "33px"}} />
                </Circle>
                }

            </Flex>

            </Box>
          </VStack>

        </>
      )
  
}


export default ChatAI;