import React, { useEffect, useState, useRef } from 'react';
import {
    Box,
    Text,
    Link,
    VStack,
    Input,
    Circle,
    Button,
    Flex,
    Stack,
    Spinner,
    Heading,
    HStack,
    Progress,
    Textarea,
    Divider,
    Grid,
    AspectRatio,
    GridItem
  } from '@chakra-ui/react';
import ChatAI from '../components/ChatAI';
import { motion, useAnimation } from "framer-motion";
import axios from "axios";




const Docus = () => {
    const FileDoc = useRef();
    const iframeChat = useRef();
    const controls = useAnimation();
    const startAnimation = () => controls.start("hover");
    const stopAnimation = () => controls.stop();
    const [corpusID, setCorpusID] = useState('');
    const [isLearn, setisLearn]= useState(false);
    const [listCorpus, setlistCorpus]= useState([]);


    useEffect(() => {

      if (listCorpus.length == 0 ){
        const ListCorpurs = JSON.parse(localStorage.getItem('corpus'));

        if (ListCorpurs){
          if (ListCorpurs.length >= 1){
            setlistCorpus(ListCorpurs);
          }
        }  
      } else {
        localStorage.setItem('corpus' ,JSON.stringify(listCorpus));
      }


    }, [listCorpus])





    const AddCorpus = (corpus_id) => {

      // Resuma el documento en 3 oraciones y generar 3 preguntas de prueba pero no proporcione respuestas. Agregue "Hola!, ha subido Documento.pdf" en la primera linea.


      setCorpusID(corpus_id);
      setlistCorpus(listCorpus => [...listCorpus, corpus_id]);
    }


    const uploadFile = () => {
      setisLearn(true);
      console.log('upload file');
        var formData = new FormData();
        formData.append("file", FileDoc.current.files[0]);
        // const API = 'http://localhost:8000/training';
        const API = 'https://api-docus.dojopy.com/training';

        axios.post(API, formData, {
            headers: {
            'Content-Type': 'multipart/form-data'
            }
        }).then((res) => {
            setisLearn(false);
            AddCorpus(res.data.corpus_id);

        }).catch((err) => {
          setisLearn(false);
          console.log(err)
        })
    }


    return (

<Grid templateColumns='20% 79%' gap={1}>

    <GridItem bg="rgb(0, 21, 41)">
        <Box
            m="5"
            height={"130px"}
          borderColor="gray.300"
          borderStyle="dashed"
          borderWidth="2px"
          rounded="md"
          shadow="sm"loa
          role="group"
          transition="all 150ms ease-in-out"
          _hover={{
            shadow: "md"
          }}
          initial="rest"
          animate="rest"
          whileHover="hover"
        >
         
              <Stack
                display="flex"
                alignItems="center"
                justify="center"
                spacing="4"
              >
                
                <Stack p="8" textAlign="center" spacing="1" placeItems={"center"}>
                  {isLearn ?
                  <>
                    <Heading fontSize="lg" color="white" fontWeight="bold" mb="1">
                      Analizando
                    </Heading>
                    <Spinner
                      thickness='4px'
                      speed='0.65s'
                      emptyColor='gray.200'
                      color='blue.500'
                      size='xl'
                    />

                  </>:
                  <>
                    <Heading fontSize="lg" color="white" fontWeight="bold">
                      Nuevo Chat
                    </Heading>
                    <Text color="white"  fontWeight="light">Carga tu documento aqui</Text>
                  </>
                  }
          
                  
                </Stack>
                
              </Stack>
            <Input
                ref={FileDoc}
              type="file"
              height="130px"
              width="20%"
              position="absolute"
              top="0"
              left="0"
              opacity="0"
              aria-hidden="true"
              accept="*"
              onDragEnter={startAnimation}
              onDragLeave={stopAnimation}
              onChange={uploadFile}
            />
        </Box>


        <Divider my="3" orientation='horizontal' />

        <Box m="2" textAlign={"center"}>

              <Heading pt="1" m="2" fontSize="lg" color="white" fontWeight="bold" textAlign={"center"}>
                  Mis documentos
                </Heading>

            {listCorpus?.map((corpus, key) => (
            <Button
            onClick={() => setCorpusID(corpus)}
            my="2"
            colorScheme='cyan'
            variant='outline'
              key={key}
              size='md'
              height='45px'
              width='95%'
              border='2px'
              isActive={corpus == corpusID}
            >
            {corpus.slice(0,21)}
            </Button>
            ))}

        </Box>

                </GridItem>


                <GridItem>
                    <AspectRatio height={"98vh"} >
                    <iframe
                        ref={iframeChat}
                        title='Chat'
                        src={`/?config=docus&corpus_id=${corpusID}&init_resume=1`}
                        allowFullScreen
                    />
                    </AspectRatio>
                </GridItem>

        </Grid>
    )
}

export default Docus;